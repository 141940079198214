
import Vue from "vue";

import { authMapActions } from "@/store/modules/auth";
import { appMapGetters } from "@/store/modules/app";

import { validateFields } from "@/helpers";
import { appBaseTheme } from "@/helpers/appCoreBaseSettings";
import FormBuilder from "../FormBuilder.vue";
import { FormBlock } from "../types";

interface ILoginData {
  loggingIn: boolean;
  error: string;
  credentials: {
    email: string;
    password: string;
  };
  hasValidCredentials: boolean;
  schemas: FormBlock[];
  formValidationData: any;
}

export default Vue.extend({
  name: "login",
  components: {
    "form-builder": FormBuilder
  },
  data(): ILoginData {
    return {
      formValidationData: {},
      loggingIn: false,
      error: "",
      credentials: {
        email: "",
        password: ""
      },
      hasValidCredentials: false,
      schemas: [
        {
          element: "html",
          id: "sectionOne",
          tagName: "div",
          classList: ["mb-8"],
          children: [
            {
              element: "html",
              id: "headerOne",
              tagName: "h3",
              innerText: "Login!",
              classList: ["font-bold", "text-lg"]
            }
          ]
        },
        {
          element: "html",
          id: "sectionTwo",
          tagName: "div",
          innerText: "",
          classList: ["mb-4"],
          children: [
            {
              element: "field",
              id: "fieldUsername",
              classList: [],
              properties: {
                key: "username",
                type: "text",
                label: "Enter email",
                placeholder: "",
                required: true,
                validations: [
                  {
                    method: "email",
                    error: "invalid email"
                  }
                ]
              }
            },
            {
              element: "field",
              id: "fieldPassword",
              classList: [],
              properties: {
                key: "password",
                type: "password",
                label: "Password",
                placeholder: "",
                required: true
              }
            }
          ]
        }
      ]
    };
  },
  watch: {
    $isLoggedIn: function(loggedIn) {
      this.$nextTick(function() {
        if (loggedIn) {
          this.$router.push("/").catch(() => {});
          this.$gtag.event("login", { method: "app" });
        }
      });
    },
    $isLoginMfa: function(loginMfa) {
      this.$nextTick(function() {
        if (loginMfa) {
          this.$router.push("/mfa").catch(() => {});
        }
      });
    },
    "credentials.email": {
      handler() {
        this.updateIsValid();
      }
    },
    "credentials.password": {
      handler() {
        this.updateIsValid();
      }
    }
  },
  methods: {
    ...authMapActions(["login"]),
    formFieldChangedHandler(data: {
      key: "email" | "password";
      value: string;
    }) {
      this.credentials[data.key] = data.value;
    },

    async loginToApp(): Promise<void> {
      const loginTimer = this.__gtagMeasureTiming("login");
      this.error = "";
      this.loggingIn = true;
      const loading = this.$loading({
        lock: true
      });

      try {
        const refs = this.$refs;
        const isValid = validateFields(refs);
        if (isValid) {
          const { email, password } = this.credentials;
          await this.login({ email, password });
        }
      } catch (e) {
        this.error = e.message;
        if (e.status === 423) {
          this.$router.push({
            path: "/emergency-maintenance",
            query: { message: e.message }
          });
        }
        if (e.status === 403) {
          this.$router.push({ path: "/403", query: { message: e.message } });
        }
        this.$log("login failed", "Error", {
          email: this.credentials.email,
          message: e.message
        });
      } finally {
        loading.close();
        this.loggingIn = false;
        loginTimer.done();
      }
    },
    async loginFormOnEnterEmail() {
      const passwordFieldRef = this.$refs.field_password as any;
      const passwordField = passwordFieldRef.$el.querySelector("input");
      if (this.credentials.email) {
        if (passwordField) passwordField.focus();
      }
    },
    async loginFormOnEnterPassword() {
      const emailFieldRef = this.$refs.field_email as any;
      const emailField = emailFieldRef.$el.querySelector("input");
      if (this.credentials.email && this.credentials.password) {
        await this.loginToApp();
      } else if (!this.credentials.email && this.credentials.password) {
        if (emailField) emailField.focus();
      }
    },
    updateIsValid() {
      this.hasValidCredentials = this.$refs
        ? validateFields(this.$refs)
        : false;
    }
  },
  computed: {
    ...appMapGetters(["getAppViewSettings", "getAppTheme"]),
    appLogo(): string {
      const { logoUrl } = this.getAppTheme;
      return logoUrl;
    },
    pageBackground(): string {
      const { views } = this.getAppTheme;
      const { login } = views;
      const image = login.backgroundImage
        ? login.backgroundImage
        : appBaseTheme.views.login.backgroundImage;
      return image;
    },
    buttons(): any[] {
      return [
        {
          disabled: this.formValidationData.formIsValid,
          title: "Login",
          size: "medium",
          class: "w-full",
          type: "primary"
        }
      ];
    }
  }
});
